.container {
  width: 320px;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 56px 20px;
  margin: 0 auto;
}

.header {
  margin-top: 16px;
  color: #FFFFFF;
}

.description {
  font-family: 'CodecPro200', sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 16.8px;
  text-align: center;
  color: #F5F5F5;
  margin-top: 16px;
}

.blocksContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  margin-top: 16px;
}

.block {
  display: flex;
  padding: 0 20px;
  align-items: center;
  height: 57px;
  width: 100%;
  font-family: 'CodecPro300', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  letter-spacing: -0.01em;
  text-align: center;
  color: var(--secondary-10);
  justify-content: center;
}

.bgSecondary {
  background-color: var(--primary-90);
}

.bgPrimary {
  background-color: var(--primary-70);
}

@media (min-width: 768px) {
  .container {
    width: 688px;
    padding: 40px 0;
  }

  .description {
    width: fit-content;
    font-size: 14px;
    line-height: 19.8px;
  }

  .img {
    width: 22px;
  }

  .header {
    margin-top: 24px;
  }

  .blocksContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0;
    margin-top: 52px;
  }

  .block {
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1280px) {
  .container {
    width: 1148px;
    padding: 56px 0;
  }

  .description {
    width: fit-content;
    font-size: 16px;
    line-height: 24px;
  }

  .blocksContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 0;
    margin-top: 72px;
  }

  .block {
    height: 57px;
    font-size: 14px;
    line-height: 19.6px;
    padding: 0 18px;
  }
}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
