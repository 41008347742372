/* InfrastructureSlider.module.scss */
.headerIcon {
  width: 18px;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 93px;
  gap: 16px;
  justify-content: center;
}

.slider {
  padding: 20px;
  height: 722px;
  background-color: #f8f7f2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (min-width: 768px) {
  .headerWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 38px;
    gap: 16px;
    width: 688px;
    justify-content: flex-start;
    margin-bottom: 20px;
    align-self: center;
  }

  .slider {
    height: 546px;
  }

  .headerIcon {
    width: 27px;
  }
}

@media (min-width: 1280px) {
  .slider {
    height: 845px;
    padding: 56px 0;
  }

  .headerWrapper {
    width: 1148px;
  }
}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
