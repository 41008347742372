.container {
  position: relative;
  overflow: hidden;
}

.bgIcon {
  position: absolute;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  left: 40%;
}

.headerContainer {
  padding-top: 50px;
  width: 280px;
  margin: 0 auto;
  padding-bottom: 20px;
  position: relative;
}

.description {
  font-family: 'CodecPro300', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;
  margin-top: 16px;
}

@media (min-width: 768px) {
  .headerContainer {
    padding-top: 60px;
    width: 688px;
    overflow: hidden;
    margin: 0 auto;
    padding-bottom: 20px;
    position: relative;
  }

  .bgIcon {
    left: 60%;
  }
}

@media (min-width: 1280px) {
  .headerContainer {
    padding-top: 56px;
    width: 1148px;
    margin: 0 auto;
    padding-bottom: 20px;
    position: relative;
  }

  .bgIcon {
    left: 32%;
    top: -44px;

    &>img {
      width: 202px;
    }
  }

  .description {
    font-size: 16px;
    line-height: 24px;
    width: 596px;
    margin: 24px auto 0;
  }
}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
