.container {
  width: 100%;
  background-image: url("~assets/images/treasures-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 624px;
  padding: 20px 20px 0;
  position: relative;

  .btnContainer {
    margin-top: 40px;
  }
}

.wrapper {
  position: absolute;
  bottom: 20px;
}

@media (min-width: 768px) {
  .wrapper {
    width: 688px;
    position: absolute;
    bottom: auto;
  }

  .container {
    height: 700px;
    padding: 40px 0 0;
  }
}

@media (min-width: 1280px) {
  .container {
    height: 819px;
    padding: 56px 0 0;
  }

  .wrapper {
    width: 1148px;
    position: absolute;
    bottom: auto;
  }
}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
