@import "styles/variables";

.container {
  background-color: #f2f3ec;

  .textContainer {
    width: 280px;
    margin: 0 auto;
    padding: 20px 0;

    .descriptionContainer {
      padding: 20px 0 20px 0;

      & > h3 {
        margin-bottom: 20px;
      }

      & > p {
        margin-bottom: 20px;
      }
    }

    .headerContainer {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    .about {
      display: flex;
      align-items: center;
      gap: 16px;

      .header {
        color: #232323;
      }
    }
  }
}

.btnsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .btn {
    border: 1px solid #d8dad0;
    background-color: transparent;
    color: #4e5141;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border: 1px solid #BEC1B1;
      background-color: #BEC1B1;
    }
  
    &:active {
      border: 1px solid #F2F3EC;
      background-color: #F2F3EC;
      color: #BEC1B1;
    }
  }
}

.infoContainer {
  .infoWrapper {
    display: flex;
    margin-bottom: 10px;

    .infoItem {
      width: 50%;
    }

    .item1 {
      font-size: 12px;
      font-weight: 500;
      font-family: "CodecPro500", sans-serif;
      line-height: 14.4px;
      letter-spacing: -0.01em;
      text-align: left;
      color: #232323;
    }

    .item2 {
      font-size: 12px;
      font-weight: 300;
      line-height: 16.8px;
      font-family: "CodecPro300", sans-serif;
      letter-spacing: -0.01em;
      text-align: right;
      color: #8c8c8c;
    }
  }

  .line {
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-color: #d8dad0;
    border-bottom-style: solid;
    margin: 14px 0 14px 0;
  }
}

@media screen and (min-width: 768px) and (max-width: 1259px) {
  .container {
    padding: 40px;

    .textContainer {
      width: 688px;

      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .headerContainer {
        width: 50%;
      }

      .descriptionContainer {
        width: 50%;
        padding: 0;
      }
    }
  }
}

@media screen and (min-width: 1280px) {
  .container {
    padding: 56px;

    .textContainer {
      width: 1148px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0;

      .headerContainer {
        gap: 32px;
        width: 50%;

        .header {
          font-size: 24px;
          line-height: 24px;
          text-transform: uppercase;
        }
      }

      .descriptionContainer {
        width: 50%;
        gap: 24px;
        display: flex;
        flex-direction: column;
        padding: 0;

        & > h3 {
          font-size: 24px;
          font-weight: 200;
          line-height: 33.6px;
          letter-spacing: -0.01em;
          text-align: left;
          color: #232323;
          margin-bottom: 0;
          font-family: "CodecPro200", sans-serif;
        }

        & > p {
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          letter-spacing: -0.01em;
          text-align: left;
          color: #8c8c8c;
          font-family: "CodecPro300", sans-serif;
          margin-bottom: 0;
        }
      }
    }
  }

  .infoContainer {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .line {
      margin: 0;
    }

    .infoWrapper {
      display: flex;

      .infoItem {
        width: 50%;
      }

      .item1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 22.4px;
        letter-spacing: -0.01em;
        text-align: left;
        text-transform: uppercase;
        color: #232323;
        font-family: "CodecPro500", sans-serif;
      }

      .item2 {
        font-size: 16px;
        font-weight: 300;
        font-family: "CodecPro300", sans-serif;
        line-height: 24px;
        letter-spacing: -0.01em;
        text-align: left;
        color: #8c8c8c;
      }
    }
  }
}
