.react-slider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filled-track {
  height: 8px;
  background: var(--primary-100);
  height: 100%;
  border-radius: 4px;
  position: relative;
}

.react-slider-thumb {
  height: 24px;
  width: 24px;
  background: var(--gray-0);
  border: 1.50px solid var(--primary-100);
  box-shadow: 0 2px 4px -2px rgba(16, 24, 40, 0.06), 0 4px 8px -2px rgba(16, 24, 40, 0.1);
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  top: -15px;
}




