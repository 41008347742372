@import "styles/variables";


.container {
  background-color: #F2F3EC;

  .textContainer {
    width: 280px;
    margin: 0 auto;
    padding: 20px 0;

    .descriptionContainer {
      padding: 20px 0 20px 0;

      &>h3 {
        margin-bottom: 20px;
      }

      &>p {
        margin-bottom: 20px;
      }
    }

    .headerContainer {
      display: flex;
      // align-items: center;
      flex-direction: column;
      gap: 16px;
    }

    .about {
      display: flex;
      align-items: center;
      gap: 16px;

      .header {
        color: #232323;
      }
    }
  }
}


.btnsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .btn {
    border: 1px solid #d8dad0;
    background-color: transparent;
    color: #4e5141;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      border: 1px solid #BEC1B1;
      background-color: #BEC1B1;
    }
  
    &:active {
      border: 1px solid #F2F3EC;
      background-color: #F2F3EC;
      color: #BEC1B1;
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1259px) {
  .container {
    padding: 40px;

    .textContainer {
      width: 688px;

      padding: 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .headerContainer {
        width: 50%;
      }

      .descriptionContainer {
        width: 50%;
        padding: 0;
      }
    }
  }
}


@media screen and (min-width: 1280px) {
  .container {
    padding: 56px;

    .textContainer {
      width: 1148px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0;

      .headerContainer {
        gap: 32px;
        width: 50%;

        .header {
          font-size: 24px;
          line-height: 24px;
          text-transform: uppercase;
        }
      }

      .descriptionContainer {
        width: 50%;
        gap: 24px;
        display: flex;
        flex-direction: column;
        padding: 0;

        &>h3 {
          font-size: 24px;
          font-weight: 200;
          line-height: 33.6px;
          letter-spacing: -0.01em;
          text-align: left;
          color: #232323;
          margin-bottom: 0;
          font-family: 'CodecPro200', sans-serif;
        }

        &>p {
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          letter-spacing: -0.01em;
          text-align: left;
          color: #8C8C8C;
          font-family: 'CodecPro300', sans-serif;
          margin-bottom: 0;
        }
      }
    }
  }
}
