@import "variables";

.calculator {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    color: #FFFFFF;
    text-transform: uppercase;
    margin-bottom: 24px;
  }

  &__description {
    width: 554px;
    font-weight: 300;
    font-family: 'CodecPro300', sans-serif;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    text-align: center;
    color: var(--gray-70);
    margin-bottom: 88px;
  }

  &__area {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  &__form {
    height: 328px;
    display: flex;
    flex-direction: column;
    width: 455px;
    gap: 24px;
    justify-content: space-between;

    &-select {
      display: flex;
      gap: 24px;
    }

    &-group {
      display: flex;
      flex-direction: column;
      width: 100%;

      label {
        font-family: 'CodecPro300', sans-serif;
        font-size: 10px;
        font-weight: 300;
        line-height: 14px;
        text-align: left;
        color: #8C8C8C;
        margin-bottom: 8px;
      }

      input, select {
        padding: 10px;
        font-size: 14px;
      }

      &--slider {
        gap: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }


  .slider {
    width: 100%;
    input[type="range"] {
      width: 100%;
    }
  }

  button {
    width: 100%;
    align-self: center;
    border-radius: 0;
    padding: 12px 20px;
    border: 1px solid var(--gray-0);
    background-color: transparent;
    cursor: pointer;
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: -0.01em;
    color: var(--gray-0);
  }

  .result-card {
    margin-left: 40px;
  }
}

.percentage-container {
  position: relative;
  background: #fff;

  & input {
    font-weight: 300;
    font-size: 16px;
    letter-spacing: -0.01em;
    color: var(--gray-100);
    width: 84px;
    padding-right: 20px;
    text-align: left;
    border: 1px solid var(--gray-40);
  }

  &::after {
    content: '%';
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--gray-60);
  }
}

.calculator__form-group--inline {
  display: flex;
  flex-direction: column;
  width: 100%;

  .calculator__inline-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--gray-0);
    border: 1px solid var(--gray-40);
    width: 100%;
    height: 36px;

    select {
      width: 105px;
      border: none;
      color: #232323;
      background: transparent;
      appearance: none;
      font-family: 'CodecPro300', sans-serif;
      font-size: 12px;
      font-weight: 300;
      line-height: 16.8px;
      letter-spacing: -0.01em;
      text-align: left;

    }

    .calculator__divider {
      margin-left: 50px;
      color: var(--gray-40);
      border-right: 1px solid var(--gray-40);
      display: inline-block;
      height: 20px;
    }

    input {
      padding-top: 2px;
      padding-bottom: 2px;
      border: none;
      border-left: 1px solid var(--gray-40);
      background-color: transparent;
      font-size: 16px;
      color: var(--gray-60);
      text-align: right;
      width: 86px;
    }

    select:focus,
    input:focus {
      outline: none;
    }
  }
}

.optionDefault {
  font-family: 'CodecPro300', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  letter-spacing: -0.01em;
  text-align: left;
  appearance: none;
}

.arrowAbsolute {
  position: absolute;
}

@media (max-width: 767px) {
  .calculator {
    &__divider {
      margin-left: 110px !important;
    }

    &__title {
      font-size: 16px;
      text-align: center;
      margin-bottom: 16px;
    }

    &__description {
      width: 100%;
      font-size: 12px;
      margin-bottom: 40px;
    }

    &__area {
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }

    &__form {
      width: 100%;
      height: auto;
      gap: 16px;

      &-group {
        width: 100%;
      }

      &-select {
        flex-direction: column;
        gap: 16px;
      }
    }

    &__divider {
      margin-left: 100px;
    }

    button {
      width: 100%;
    }

    .result-card {
      margin: 0 0 0;
      width: 100%;
    }
  }

  .percentage-container {
    input {
      width: 62px;
    }
  }

  .calculator__form-group--inline {
    flex-direction: column;

    .calculator__inline-group {
      width: 100%;
    }
  }

  .slider {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .calculator__title {
    width: 412px;
  }

  .calculator__description {
    margin-bottom: 48px;
  }

  .calculator__form {
    gap: 28px;
    justify-content: flex-start;
  }
}

@media (min-width: 1280px) {
  .calculator__description {
    margin-bottom: 48px;
    font-family: 'CodecPro300', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: center;
  }

  .calculator__title {
    width: 640px;
  }
}
