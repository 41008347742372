@import "styles/variables";

.container {
  width: 320px;
  margin: 0 auto;
  padding: 48px 20px 40px 20px;

  .headerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-direction: column;
    margin-bottom: 10px;
  }
}

.contentWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 48px 40px 24px 40px;
    gap: 24px;
    width: 768px;

    .headerContainer {
      flex-direction: row;
      gap: 32px;
    }
  }

  .contentWrapper {
    display: flex;
    gap: 32px;
    flex-direction: row;
    margin-top: 45px;
    align-items: flex-start;
  }
}

@media screen and (min-width: 1280px){
  .container {
    padding: 56px;
    width: 1280px;
    gap: 80px;

    .headerContainer {
      flex-direction: column;
    }
  }

  .contentWrapper {
    justify-content: space-between;
  }
}

@media screen and (min-width: 2048px){
  .contentWrapper {
    margin-top: 66px;
  }

  .container {
    .headerContainer {
      img {
        width: 62px;
      }
    }
  }
}
