.container {
  width: 100%;
  gap: 24px;
  background: #3F4134;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  margin-top: 16px;
  align-items: center;
}

.textBlock {
  gap: 8px;
  display: flex;
  flex-direction: column;

  .header {
    font-size: 12px;
    font-weight: 300;
    font-family: 'CodecPro300', sans-serif;
    line-height: 16.8px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #FFFFFF;
  }
}

.description {
  font-size: 10px;
  font-family: 'CodecPro300', sans-serif;
  font-weight: 300;
  line-height: 14px;
  text-align: center;
  color: #8C8C8C;
}

.btnsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .btn {
    border: 1px solid #FFFFFF;
    background-color: #3F4134;
    color: #FFFFFF;
    padding: 10px 16px 10px 16px;
    gap: 8px;
    font-size: 12px;
    font-weight: 300;
    font-family: 'CodecPro300', sans-serif;
    line-height: 16.8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:hover {
      background: #6C705A;
    }

    &:active {
      background: #D8DAD0;
    }
  }

  .btnWhite {
    background: #FFFFFF;
    color: #3F4134;

    &:hover {
      background-color: #BEC1B1;
    }

    &:active {
      background-color: #F2F3EC;
      color: #BEC1B1;
    }
  }

  .btnWhite {
    background: #FFFFFF;
    color: #3F4134;

    &:hover {
      background-color: #BEC1B1;
    }

    &:active {
      background-color: #F2F3EC;
      color: #BEC1B1;
    }
  }
}

@media screen and (min-width: 768px) {
  .container {
    width: 40%;
    padding: 40px 32px 40px 32px;
    justify-content: space-between;
    margin-top: 0;
  }

  .btnsWrapper {
    gap: 16px;
  }

  .textBlock {
    width: 100%;

    .header {
      text-align: left;
    }

    .description {
      text-align: left;
    }
  }
}

@media screen and (min-width: 1280px) {
  .container {
    padding: 40px 32px 40px 32px;
    justify-content: space-between;
    margin-top: 0;
    width: 32.6%;
    height: 576px;
    gap: 0;
  }

  .btnsWrapper {
    gap: 16px;

    .btn {
      font-size: 16px;
      line-height: 24px;
    }

    .btnWhite {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .textBlock {

    .header {
      font-size: 16px;
      line-height: 22.4px;
    }

    .description {
      font-size: 16px;
      line-height: 19.6px;
    }
  }
}

//@media (min-width: 1536px) {
//  .container {
//    padding: 40px 32px 40px 32px;
//    justify-content: space-between;
//    margin-top: 0;
//    width: 40.6%;
//    height: 776px;
//    gap: 0;
//  }
//
//  .btnsWrapper {
//    width: 100%;
//    gap: 16px;
//
//    .btn {
//      font-size: 20px;
//      line-height: 26px;
//    }
//
//    .btnWhite {
//      font-size: 20px;
//      line-height: 26px;
//    }
//  }
//
//  .textBlock {
//
//    .header {
//      font-size: 20px;
//      line-height: 30.4px;
//    }
//
//    .description {
//      margin-top: 20px;
//      font-size: 20px;
//      line-height: 25.6px;
//      width: 100%;
//    }
//  }
//}
//
//@media (min-width: 2048px) {
//  .container {
//    padding: 40px 32px 40px 32px;
//    justify-content: space-between;
//    margin-top: 0;
//    width: 44.6%;
//    height: 976px;
//    gap: 0;
//  }
//
//  .btnsWrapper {
//    width: 100%;
//    gap: 16px;
//
//    .btn {
//      font-size: 32px;
//      line-height: 38.4px;
//      padding: 16px 22px 16px 22px;
//      height: auto;
//    }
//
//    .btnWhite {
//      font-size: 32px;
//      line-height: 38.4px;
//      padding: 16px 22px 16px 22px;
//      height: auto;
//    }
//  }
//
//  .textBlock {
//
//    .header {
//      font-size: 34px;
//      line-height: 38.4px;
//    }
//
//    .description {
//      margin-top: 20px;
//      font-size: 28px;
//      line-height: 31.6px;
//      width: 100%;
//    }
//  }
//}
