.accordion-container {
  width: 100%;
  border-radius: 8px;
}

.accordion-item {
  padding: 0px 0;
  gap: 8px;

}

.accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  padding: 15px;
  font-size: 18px;
  font-weight: bold;
  gap: 8px;

  .question-header {
    font-size: 12px;
    font-weight: 300;
    line-height: 16.8px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #232323;
    font-family: 'CodecPro300', sans-serif;
  }
}

.accordion-content {
  padding: 0 16px;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  font-family: 'CodecPro300', sans-serif;
  text-align: left;
  color: #8C8C8C;

  .question-answer {
    padding-bottom: 15px;
    font-family: 'CodecPro300', sans-serif;
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    text-align: left;
  }
}


@media screen and (min-width: 768px) {
  .accordion-header {
    flex-direction: row-reverse;
    padding: 24px 32px 8px 32px;

    .question-header {
      width: 95%;
    }
  }

  .accordion-content {
    padding: 0 16px 0 68px;

    .question-answer {
      width: 95%;
      padding-bottom: 24px;
    }
    }
}

@media screen and (min-width: 768px) {
  .accordion-header {
    flex-direction: row-reverse;
    padding: 24px 32px 8px 32px;

    .question-header {
      width: 95%;
    }
  }

  .accordion-content {
    padding: 0 16px 0 58px;

    .question-answer {
      width: 95%;
      padding-bottom: 24px;
    }
  }
}

@media screen and (min-width: 1280px) {
  .accordion-header {

    .question-header {
      font-size: 16px;
      line-height: 24px;
    }
  }

  .accordion-content {
    padding: 0 16px 0 78px;

    .question-answer {
      font-size: 14px;
      line-height: 19.6px;
    }
  }
}

//@media (min-width: 1536px) {
//  .accordion-header {
//    padding: 24px 32px 12px 32px;
//
//    .question-header {
//      font-size: 24px;
//      line-height: 28px;
//    }
//  }
//
//  .accordion-content {
//    padding: 0 18px 0 80px;
//
//    .question-answer {
//      font-size: 20px;
//      line-height: 25.6px;
//      padding-bottom: 24px;
//    }
//  }
//}
//
//@media (min-width: 2048px) {
//  .accordion-icon {
//    width: 30px;
//    height: 30px;
//  }
//
//  .accordion-header {
//    padding: 34px 32px 22px 32px;
//
//    .question-header {
//      font-size: 30px;
//      line-height: 37px;
//    }
//  }
//
//  .accordion-content {
//    padding: 0 18px 0 110px;
//
//    .question-answer {
//      font-size: 28px;
//      line-height: 34.6px;
//      padding-bottom: 34px;
//    }
//  }
//}
