@import "styles/variables";

.btn {
  width: 100%;
  height: 37px;
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-family: 'CodecPro300', sans-serif;
  font-weight: 300;
  letter-spacing: -0.01em;
  text-align: center;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
  line-height: 16.8px;

  &:hover {
    background: #6C705A;
    cursor: pointer;
    border-color: #6C705A;
  }
}

@media screen and (min-width: 768px) {
  .btn {
    height: 40px;
    font-size: 14px;
    line-height: 19.6px;
  }
}

@media screen and (min-width: 1280px) {
  .btn {
    padding: 12px 20px 12px 20px;
    height: 48px;
    gap: 8px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: center;
  }
}

@media (min-width: 1536px) {

}

//@media (min-width: 2048px) {
//  .btn {
//    padding: 0 20px;
//    width: 420px;
//    height: 115px;
//    gap: 8px;
//    font-size: 32px;
//    line-height: 24px;
//    letter-spacing: -0.01em;
//    text-align: center;
//  }
//}
