@import "styles/variables";

.btn {
  background: #FFFFFFB2;
  width: 154px;
  height: 37px;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-width: 0;
  font-family: 'CodecPro300', sans-serif;
  font-weight: 300;
  letter-spacing: -0.01em;
  text-align: center;
  color: #3F4134;
  font-size: 12px;
  line-height: 16.8px;

  &:hover {
    background: #ffff;
    cursor: pointer;
  }
}

@media screen and (min-width: 1280px) {
  .btn {
    padding: 0 20px;
    width: 210px;
    height: 48px;
    gap: 8px;
    border-width: 0;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: center;
  }
}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {
  .btn {
    padding: 0 20px;
    width: 420px;
    height: 115px;
    gap: 8px;
    border-width: 0;
    font-size: 32px;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: center;
  }
}
