.imageContainer {
  width: 320px;
  padding: 0 20px;
}

.arrowsWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px 0 20px;
  gap: 12px;
}

.sliderContainer {
  width: 320px;
  height: 331px;
  display: flex;
  flex-direction: column;
}

.mobTextContainer {
  flex-direction: column;
  gap: 4px;
  display: flex;
  margin-top: 16px;
}

.step {
  font-family: 'CodecPro500', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #A3A068;
}

.title {
  font-family: 'CodecPro500', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #232323;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.description {
  font-family: 'CodecPro200', sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 16.8px;
  text-align: left;
  color: #8C8C8C;
}

@media (min-width: 768px) {
  .imageContainer {
    width: 522px;
    padding: 0 0;
  }

  .sliderContainer {
    width: 768px;
    height: 397px;
    display: flex;
    flex-direction: row;
  }

  .imgContainer {
    width: 350px;
    height: 371px;
    display: flex;
    flex-direction: column;
  }

  .container {
    width: 688px;
    align-self: center;
    display: flex;
    flex-direction: row;
  }

  .step {
    font-size: 14px;
    line-height: 20.4px;
  }

  .title {
    font-size: 14px;
    line-height: 20.4px;
  }

  .mobTextContainer {
    padding-left: 20px;
    padding-right: 10px;
  }

  .descriptionContainer {
    gap: 24px;
  }

  .description {
    font-size: 14px;
    line-height: 20.4px;
  }

  .line {
    border: 1px solid #D8DAD0;
    width: 290px;
    margin-top: 12px;
  }

  .progressWrapper {
    display: flex;
    gap: 24px;
    width: 100%;
    align-items: center;
    padding-left: 20px;
  }

  .offText {
    font-family: 'CodecPro200', sans-serif;
    font-size: 14px;
    font-weight: 200;
    line-height: 20.4px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .inText {
    font-family: 'CodecPro300', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .arrowsWrapper {
    justify-content: center;
    align-items: center;
    padding: 0 16px;
  }

  .numbersWrapper {
    width: 39px;
  }
}

@media (min-width: 1280px) {
  .inText {
    font-size: 16px;
    line-height: 24px;
  }

  .offText {
    font-family: 'CodecPro200', sans-serif;
    font-size: 16px;
    font-weight: 200;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .container {
    width: 1148px;
    align-self: center;
    display: flex;
    flex-direction: row;
  }

  .imgContainer {
    width: 522px;
    height: 615px;
  }

  .imageContainer {
    width: 522px;
    height: 615px;
    padding: 0 0;
  }

  .sliderContainer {
    width: 1148px;
    height: 600px;
  }

  .step {
    font-size: 16px;
    line-height: 22.4px;
  }

  .title {
    font-size: 16px;
    line-height: 22.4px;
  }

  .mobTextContainer {
    padding-top: 20px;
    padding-left: 30px;
    padding-right: 180px;
  }

  .description {
    font-size: 16px;
    line-height: 24px;
  }

  .line {
    border: 1px solid #D8DAD0;
    width: 390px;
    margin-top: 24px;
  }

  .progressWrapper {
    display: flex;
    gap: 24px;
    width: 100%;
    align-items: center;
    padding-left: 20px;
  }

  .offText {
    font-family: 'CodecPro200', sans-serif;
    font-size: 16px;
    font-weight: 200;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .inText {
    font-family: 'CodecPro300', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .numbersWrapper {
    width: 44px;
  }
}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
