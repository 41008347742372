.container {
  width: 320px;
  display: flex;
  flex-direction: column;
  padding: 56px 20px;
  margin: 0 auto;
  background-color: var(--primary-100);
  padding: 20px;
  gap: 20px;
}

.content {
  display: flex;
  flex-direction: column;
}

.header {
  margin-top: 16px;
  color: #ffffff;
  text-align: left;
}

.description {
  font-family: "CodecPro200", sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 16.8px;
  text-align: center;
  color: var(--gray-70);
  margin-top: 16px;
  text-align: left;
}

.btnsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .btn {
    border: 1px solid #ffffff;
    background-color: #3F4134;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #6C705A;
    }

    &:active {
      background: #D8DAD0;
    }
  }
}

@media (min-width: 768px) {
  .container {
    width: 688px;
    padding: 40px 40px;
    flex-direction: row;
    align-items: center;
  }

  .description {
    width: fit-content;
    font-size: 14px;
    line-height: 19.8px;
  }

  .img {
    max-width: 300px;
    height: 100%;
  }

  .header {
    margin-top: 24px;
  }
}

@media (min-width: 1280px) {
  .container {
    width: 1148px;
    padding: 56px 56px;
    flex-direction: row;
  }

  .description {
    width: fit-content;
    font-size: 16px;
    line-height: 24px;
  }

  .img {
    max-width: 100%;
    height: 100%;
  }
}

@media (min-width: 1536px) {
}

@media (min-width: 2048px) {
}
