.section {
  width: 100%;
  background-image: url("~assets/images/map.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 1236px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
}

.container {
  width: 320px;
  height: 100%;
  background-position: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  .btnContainer {
    margin-top: 40px;
  }
}

.topGradient {
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, #131313 0%, rgba(19, 19, 19, 0) 100%);
  width: 100%;
  height: 70%;
}

.rightGradient {
  background: linear-gradient(270deg, #131313 0%, rgba(19, 19, 19, 0) 100%);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  width: 50%;
  height: 100%;
}

.reverse {
  flex-direction: column-reverse;
}

.icon {
  width: 20px;
  height: 20px;
}

.mainIcon {
  width: 26px;
  height: 26px;
}

.bottomGradient {
  background: linear-gradient(0, #131313 0%, rgba(19, 19, 19, 0) 100%);
  width: 100%;
  height: 70%;
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.8;
  z-index: 2;
}

.textContainer {
  padding: 20px;
  position: relative;
  z-index: 10;

  .descriptionContainer {
    padding: 20px 0 20px 0;

    &>h3 {
      margin-bottom: 20px;
    }

    &>p {
      margin-bottom: 20px;
    }
  }
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 16px;

  h2 .header {
    color: #FFFFFF;
  }
}

.line {
  border: 0.5px solid #3F4134;
}

.mobilePart {
}

.elementContainer {
  padding: 12px 8px 12px 8px;
  gap: 12px;
  display: flex;
  flex-direction: column;
}

.infoWrapper {
  gap: 8px;
}

.dataContainer {
  padding: 4px 8px 4px 8px;
  gap: 8px;
  background: #8A8F7366;
  font-family: 'CodecPro300', sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
}

.rightElementContainer {
  align-items: flex-end;
}

.mapBg {
  width: 100%;
  height: 706px;
  position: absolute;
  z-index: 1;
  background-repeat: no-repeat;
  background-image: url('~assets/svg/map-lines.svg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  bottom: 0;
  right: 0;
}

.firstLocation {
  position: absolute;
  top: 700px;
  left: 230px;
  z-index: 5;
}

.pinLine {
  height: 24px;
}

.bottomWrapper {
  height: 31px;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.description {
  color: #A4A892;
  font-family: 'CodecPro300', sans-serif;
  font-size: 8px;
  line-height: 11.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  gap: 4px;

}

.pinLineWrapper {
  padding-left: 9px;
}

.mainPinLineWrapper {
  padding-left: 4px;
}

.pinTitle {
  font-family: 'CodecPro500', sans-serif;
  font-size: 8px;
  line-height: 11.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #fff;
}

.secondLocation {
  position: absolute;
  top: 784px;
  left: 60px;
  z-index: 5;
}

.thirdLocation {
  position: absolute;
  top: 900px;
  left: 126px;
  z-index: 5;
}

.location4 {
  position: absolute;
  top: 968px;
  left: 25px;
  z-index: 5;
}

.location5 {
  position: absolute;
  top: 980px;
  left: 158px;
  z-index: 5;
}

.location6 {
  position: absolute;
  top: 1056px;
  left: 61px;
  z-index: 5;
}

.location7 {
  position: absolute;
  top: 1123px;
  left: 163px;
  z-index: 5;
}

.btnWrapper {
  position: absolute;
  bottom: 10px;
  z-index: 4;
  left: 20px;
  right: 20px;
}

@media (min-width: 520px) {
  .mapBg {
    width: 100%;
    height: 706px;
    position: absolute;
    z-index: 1;
    background-repeat: no-repeat;
    background-image: url('~assets/svg/map-lines.svg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    bottom: 0;
    right: 0;
  }
}

@media (min-width: 768px) {
  .section {
    width: 100%;
    background-image: url("~assets/images/map.webp");
    background-repeat: no-repeat;
    background-size: cover;
    height: 1236px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;

  }

 .container {
   width: 768px;
 }

  .mapBg {
    width: 100%;
    height: 1260px;
    position: absolute;
    z-index: 1;
    background-repeat: no-repeat;
    background-image: url('~assets/svg/map-tablet.svg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    bottom: 0;
    right: 0;
  }

  .line {
    position: relative;
    margin: 0 40px;
    width: calc(100% - 80px);
  }

  .dataWrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0 40px;
    z-index: 5;
  }

  .dataContainer {
    padding: 4px 8px 4px 8px;
    gap: 8px;
  }

  .dataText {
    font-size: 10px;
    font-weight: 300;
    line-height: 14px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
  }

  .topGradient {
   z-index: 3;
  }

  .textContainer {
    padding: 40px;
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .headerContainer {
      width: 50%;
    }

    .descriptionContainer {
      width: 50%;
      padding: 0;

      &>h3 {
        margin-bottom: 20px;
      }

      &>p {
        margin-bottom: 20px;
      }
    }
  }

  .firstLocation {
    top: 438px;
    left: 412px;
  }

  .bottomWrapper {
    height: 56px;
    gap: 8px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .description {
    font-size: 14px;
    line-height: 19.6px;
  }

  .pinLineWrapper {
    padding-left: 19px;
  }

  .pinTitle {
    font-family: 'CodecPro300', sans-serif;
    font-size: 12px;
    line-height: 16.8px;
  }

  .secondLocation {
    top: 520px;
    left: 54px;
  }

  .thirdLocation {
    top: 646px;
    left: 292px;
  }

  .mainPinLineWrapper {
    padding-left: 2px;
  }

  .location4 {
    top: 764px;
    left: 37px;
  }

  .location5 {
    top: 795px;
    left: 347px;
  }

  .location6 {
    top: 940px;
    left: 194px;
  }

  .location7 {
    top: 1044px;
    left: 380px;
  }

  .location8 {
    position: absolute;
    top: 636px;
    left: 551px;
    z-index: 5;
  }

  .btnWrapper {
    width: 194px;
    position: absolute;
    bottom: 40px;
    z-index: 4;
    right: 16px;
    left: auto;
  }
}

@media (min-width: 1280px) {
  .section {
    width: 100%;
    background-image: url("~assets/images/map.webp");
    background-repeat: no-repeat;
    background-size: cover;
    height: 1236px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 40px;

  }

  .container {
    width: 1260px;
  }

  .dataContainer {
    gap: 8px;
    background: #8A8F7366;
    font-family: 'CodecPro300', sans-serif;
    font-weight: 300;
    text-align: left;
    padding: 8px 12px 8px 12px;
    font-size: 14px;
    line-height: 19.6px;
  }

  .mapBg {
    width: 100%;
    height: 1187px;
    z-index: 1;
    background-repeat: no-repeat;
    background-image: url('~assets/svg/mapdesctop.svg');
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    bottom: 0;
    right: 0;
  }

  .firstLocation {
    position: absolute;
    top: 496px;
    left: 626px;
    z-index: 5;
  }

  .bottomWrapper {
    height: 62px;
    gap: 8px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .pinTitle {
    font-family: 'CodecPro500', sans-serif;
    font-size: 16px;
    line-height: 24px;
  }

  .description {
    font-family: 'CodecPro300', sans-serif;
    font-size: 14px;
    line-height: 19.6px;
  }

  .pinLineWrapper {
    padding-left: 19px;
  }

  .secondLocation {
    position: absolute;
    top: 587px;
    left: 139px;
  }

  .thirdLocation {
    top: 688px;
    left: 507px;
  }

  .location4 {
    top: 827px;
    left: 202px;
  }

  .location5 {
    top: 839px;
    left: 561px;
  }

  .location6 {
    top: 978px;
    left: 350px;
  }

  .location7 {
    top: 1086px;
    left: 552px;
  }

  .location8 {
    top: 673px;
    left: 771px;
  }

  .btnWrapper {
    width: 231px;
    position: absolute;
    bottom: 40px;
    z-index: 4;
    right: 16px;
    left: auto;
  }
}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
