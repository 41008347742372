.span1 {
  font-family: 'CodecPro300', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #232323;
}

.span2 {
  font-family: 'CodecPro200', sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 16.8px;
  text-align: left;
  color: #8C8C8C;
}

.result-card {
  height: 100%;
  width: 337px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #FAFAFA;


  color: black;

  &__header {
    background-color: #3F4134;
    padding: 32px;
  }

  &__title {
    color: #AFAFAF;
    margin-bottom: 8px;
    font-family: 'CodecPro300', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 16.8px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  &__cost {
    color: #FFFFFF;
    font-family: 'CodecPro500', sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  &__list {
    list-style: none;
    padding: 24px;
    margin: 0;


    li {
      display: flex;
      justify-content: space-between;
      gap: 4px;
      padding-top: 3px;
      padding-bottom: 3px;
    }
  }

  &__footer {
    border-top: 1px solid #D8DAD0;
    padding: 24px;
  }

  &__button {
    padding: 12px 20px;
    border: 1px solid #D8DAD0 !important;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'CodecPro300', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 16.8px;
    text-align: left;
    color: #232323;


    &:hover {
      background-color: #3F4134;
      color: white !important;
    }
  }

  @media (max-width: 768px) {
    font-size: 12px;
    text-align: left;

    &__button {
      width: 100%;
    }
  }
}

@media (min-width: 1280px) {
  .span1 {
    font-size: 16px;
    line-height: 24px;
  }

  .span2 {
    font-size: 16px;
    line-height: 22.4px;
  }
}
