@import "styles/variables";

.btn {
  //styleName: Desktop/Main/Regular;
  letter-spacing: -0.01em;
  width: 100%;
  height: 37px;
  padding: 10px 16px 10px 16px;
  gap: 8px;
  border-width: 0;
  font-family: 'CodecPro300', sans-serif;
  text-align: center;
  background: #FFFFFF33;
  backdrop-filter: blur(5px);
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  color: #FFFFFF;

  &:hover {
    background: rgba(169, 166, 166, 0.2);
    backdrop-filter: blur(5px);
    cursor: pointer;
  }
}


@media screen and (min-width: 1260px) {
  .btn {
    width: 100%;
    height: 48px;
    padding: 12px 20px 12px 20px;
    gap: 8px;
    border-width: 0;
    font-family: 'CodecPro300', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #3F4134;
  }
}
