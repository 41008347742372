@import "styles/variables";

.headerText {
  width: 292px;
}

.description {
  color: #232323;
  text-align: center;
}

.textContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 280px;
  gap: 30px;
}

.container {
  width: 100%;
  background-image: url("~assets/images/home_bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  padding: 70px 20px 0;

  .btnContainer {
    margin-top: 40px;
  }
}

@media (min-width: 600px) {
  .headerText {
    width: 382px;
  }

  .textContainer {
    width: 382px;
    gap: 30px;
  }

  .container {
    height: 800px;
    padding-top: 130px;
  }
}

@media (min-width: 768px) {
  .container {
    height: 1024px;
    padding-top: 140px;
  }

  .headerText {
    width: 584px;
  }

  .textContainer {
    width: 474px;
  }
}

@media (min-width: 1280px) {
  .headerText {
    width: 892px;
  }

  .description {
    width: 573px;
    font-size: 16px;
    line-height: 24px;
  }

  .container {
    height: 60.6vw;
    padding-top: 130px;
  }
}

@media (min-width: 1536px) {
  .description {
    width: 756px;
  }

  .container {
    height: 60.6vw;
    padding-top: 150px;
    background-position: center;
  }
}

@media (min-width: 2048px) {

  .headerText {
    width: 1200px;
  }

  .description {
    width: 1200px;
  }

  .container {
    padding-top: 10vw;
    height: 60.6vw;
  }
}
