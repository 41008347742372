.inputLabel {
  font-family: 'CodecPro300', sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  color: #8C8C8C;
}

.formGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  position: relative;
}

.arrowAbsolute {
  position: absolute;
  top: 31px;
  right: 8px;
}

.selection {
  border: 1px solid #E3E3E3;
  padding: 8px 12px 8px 12px;
  height: 36px;
  font-family: 'CodecPro300', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  letter-spacing: -0.01em;
  text-align: left;
  appearance: none;
}

.percentageContainer {
  position: relative;
  background: #fff;
  width: 62px;

  & input {
    color: #232323;
    width: 62px;
    padding-right: 20px;
    border: 1px solid #E3E3E3;
    font-family: 'CodecPro300', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 16.8px;
    letter-spacing: -0.01em;
    text-align: left;

  }

  &::after {
    content: '%';
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--gray-60);
  }
}

@media (min-width: 768px) {
  .inputLabel {
    font-size: 10px;
    line-height: 14px;
  }
}

@media (min-width: 1280px) {

}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
