.accordionContainer {
  width: 280px;
  background: #F2F3EC;
  padding: 16px;
  position: relative;
  overflow: hidden;
}

.bgIcon {
  position: absolute;
  right: -107px;
  top: 0;
  z-index: 10;
  width: 296px;
  height: 349px;
  opacity: 0.8;

  img {
    width: 296px;
    height: 349px;
  }
}

.header {
  text-align: left;
  margin-bottom: 72px;
}

.accordionItem {
  padding: 8px 0;
  border-top: 1px solid #D8DAD0;
  z-index: 21;
  position: relative;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
}

.toggleButton {
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  img {
    width: 17px;
  }
}

.title {
  font-family: 'CodecPro300', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 16.8px;
  letter-spacing: -0.01em;
  text-align: left;
}

.content {
  font-family: 'CodecPro300', sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
}

.accordionContent {
  overflow: hidden;
  color: #333;
  line-height: 1.5;
}

@media (min-width: 768px) {
  .accordionContainer {
    width: 320px;
  }
  .title {
    font-family: 'CodecPro300', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 22.4px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .content {
    font-family: 'CodecPro300', sans-serif;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px;
    text-align: left;
  }

  .bgIcon {
    position: absolute;
    right: -77px;
    width: 296px;
    height: 349px;
    opacity: 0.8;
  }
}

@media (min-width: 1280px) {
  .accordionContainer {
    width: 455px;
    padding: 32px 32px 8px;
  }
  .title {
    font-family: 'CodecPro300', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .content {
    font-family: 'CodecPro300', sans-serif;
    font-size: 14px;
    font-weight: 300;
    line-height: 19.6px;
    text-align: left;
  }

  .bgIcon {
    right: 0;
  }
}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
