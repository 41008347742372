.arrow-svg path {
    stroke: #232323;
}

.arrow-svg {
    width: 30.4px;
}

.arrow-svg.disable path {
    stroke: #D8DAD0;
}

.wrapper {
    border: 3px solid blue;
}

.container {
    background-color: #D8DAD0;
    height: 1.5px;
    width: 121px;

    &>div {
        height: 1.5px !important;
    }
}

.completedStyles {
    background-color: #A3A068;
    height: 1.5px;
}

@media screen and (min-width: 1280px) {
    .arrow-svg {
        width: 60.8px;
    }
  .infrastructureContainer {
      .slick-list {
          .slick-track {
              .slick-slide {
                  width: 522px;
              }
          }
      }
  }

    .container {
        background-color: #D8DAD0;
        height: 1.5px;
        width: 161px;

        &>div {
            height: 1.5px !important;
        }
    }
}
