@import "src/styles/variables";

.container {
  padding: 24px 20px 50px;
  width: 320px;
  margin: 0 auto;

  img {
    width: 100%;
  }

  h2 {
    margin-bottom: 24px;
  }
}

.devInfoWrapper {
  text-align: left;
}

.surname {
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #232323;
  font-family: 'CodecPro500', sans-serif;
  margin-top: 16px;
}

.about {
  font-size: 12px;
  font-weight: 300;
  font-family: 'CodecPro300', sans-serif;
  line-height: 16.8px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #232323;
  margin-top: 24px;
}

.bottomBlock {
  margin-top: 16px;

  &>img {
    width: 18px;
  }
}

.quote {
  font-family: 'CodecPro200', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 19.6px;
  text-align: left;
  color: #8C8C8C;
  margin-top: 16px;
}

@media screen and (min-width: 768px) {
  .container {
    padding: 56px 40px 44px 40px;
    display: flex;
    width: 768px;
    gap: 4%;

    img {
      width: 48%;
    }
  }

  .header {
    text-align: left;
    margin-bottom: 64px;
  }

  .devInfoWrapper {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
  }

  .surname {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.4px;
    letter-spacing: -0.01em;
    text-align: left;
    color: #232323;
    margin-top: 18px;
    font-family: 'CodecPro500', sans-serif;
  }

  .about {
    margin-top: 32px;
    color: #8C8C8C;
    font-size: 12px;
    font-family: 'CodecPro300', sans-serif;
    font-weight: 300;
    line-height: 16.8px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  .bottomBlock {
    margin-top: 12px;
    width: 48%;

    img {
      width: 33.5px;
      margin-bottom: 24px;
    }
  }

  .quote {
    color: #232323;
    font-size: 14px;
    font-weight: 300;
    font-family: 'CodecPro300', sans-serif;
    line-height: 19.6px;
    text-align: left;
  }
}

@media screen and (min-width: 1280px) {
  .container {
    width: 1280px;
    padding: 56px;
  }

  .quote {
    font-size: 24px;
    font-weight: 200;
    line-height: 33.6px;
    width: 484px;
    font-family: 'CodecPro200', sans-serif;
  }

  .surname {
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
    font-family: 'CodecPro500', sans-serif;
  }

  .about {
    width: 484px;
    margin-top: 72px;
    font-size: 14px;
    line-height: 19.6px;
  }

}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
