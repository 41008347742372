.container {
  width: 100%;
  padding: 74px 20px 20px;
  background-color: #232323;
  overflow: hidden;
}

.wrapper {
  width: 280px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .wrapper {
    width: 688px;
  }
}

@media (min-width: 1280px) {
  .wrapper {
    width: 832px;
  }

  .container {
    padding: 56px 20px 56px;
  }
}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
