.sliderContainer {
  margin-top: 16px;
  overflow: hidden;
}

.arrowsWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 236px;
  right: 0;
}

.mobTextContainer {
  flex-direction: column;
  gap: 4px;
  display: flex;
  margin-top: 16px;
  height: 287px;
}

.title {
  font-family: 'CodecPro500', sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #232323;
}

.type {
  font-family: 'CodecPro300', sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 14.4px;
  letter-spacing: -0.01em;
  text-align: left;
  color: #8C8C8C;
}

.description {
  font-family: 'CodecPro200', sans-serif;
  font-size: 12px;
  font-weight: 200;
  line-height: 16.8px;
  text-align: center;
  color: #232323;
}

.blockWrapper {
  padding: 4px 4px 4px 12px;
  gap: 8px;
  display: flex;
  justify-content: center;
  background-color: #EFEFE7;
  align-items: center;
}

.valueTitle {
  font-family: 'CodecPro300', sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  color: #4E5141;
}

.smallBlock {
  box-shadow: 0px 1px 4px 0px #00000014;
  background-color: #F5F5F5;
  padding: 8px;
  font-family: "CodecPro300", sans-serif;
  font-size: 10px;
  font-weight: 300;
  line-height: 14px;
  text-align: left;
  color: #232323;
}

.blocksWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.wrapper {
  position: relative;
}

.prevBtn {
  position: absolute;
  left: 0;
  top: 0;
}

.nextBtn {
  position: absolute;
  right: 0;
  top: 0;
}

.btnWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btnGreen {
  background-color: #3F4134;
  color: #FFFFFF;

  &:hover {
    background-color: #6C705A;
  }

  &:active {
    background-color: #D8DAD0;
  }
}

.btnWhite {
  border: 1px solid #D8DAD0;
  color: #4E5141;

  &:hover {
    border: 1px solid #BEC1B1;
    background-color: #BEC1B1;
  }

  &:active {
    border: 1px solid #F2F3EC;
    background-color: #F2F3EC;
    color: #BEC1B1;
  }
}

@media (min-width: 768px) {
  .blocksWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 40px;
    justify-content: center;
  }

  .title {
    font-size: 14px;
  }

  .type {
    font-size: 14px;
  }

  .description {
    margin-top: 16px;
  }

  .titleWrapper {
    margin-top: 24px;
  }

  .prevBtn {
    position: absolute;
    left: 45%;
    top: 0;
  }

  .nextBtn {
    position: absolute;
    right: 45%;
    top: 0;
  }

  .mobTextContainer {
    flex-direction: column;
    gap: 4px;
    display: flex;
    margin-top: 16px;
    height: 240px;
  }

  .btnWrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 294px;
    margin: 0 auto;
  }
}

@media (min-width: 1280px) {
  .prevBtn {
    left: 30px;
    top: -360px;
  }

  .nextBtn {
    right: 30px;
    top: -360px;
  }

  .description {
    margin-top: 16px;
  }

  .titleWrapper {
    margin-top: 24px;
  }

  .mobTextContainer {
    flex-direction: column;
    gap: 4px;
    display: flex;
    margin-top: 16px;
    height: 240px;
  }

  .btnWrapper {
    display: flex;
    flex-direction: row;
    gap: 24px;
    width: 380px;
    margin: 84px auto 48px;
  }

  .title {
    font-size: 16px;
    line-height: 22.4px;
  }

  .type {
    font-size: 16px;
    line-height: 22.4px;
  }

  .description {
    width: 582px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 24px;
  }

  .valueTitle {
    font-size: 14px;
    line-height: 19.6px;
  }

  .smallBlock {
    font-size: 14px;
    line-height: 19.6px;
  }
}

@media (min-width: 1536px) {

}

@media (min-width: 2048px) {

}
